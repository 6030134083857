import React from "react";
import { ScreenContainer } from "../../components/common/screenContainer";
import { Button } from "../../components/common/button/button";
import { useStripeAccount } from "./useStripeAccount";

export const ConnectStripeAccount = () => {
  const { onCreateAccount } = useStripeAccount();
  return (
    <ScreenContainer loading={false} title="Account">
      <div className="w-full pt-4">
        <text>
          Get started by creating/connecting your account to start accepting
          payments
        </text>
        <div className="mt-4" />
        <div className="w-1/2">
          <Button title="Create Account" onClick={onCreateAccount} />
        </div>
      </div>
    </ScreenContainer>
  );
};
