import React, { useState } from "react";
import parse from "html-react-parser";
import { IShop, ProductGroup } from "../../redux/reducers/shop.reducer";
import { Town } from "../admin/towns/Towns.types";
import { Country, CountryModalProps } from "../admin/countries/Countries.types";
import { City } from "../admin/cities/Cities.types";

type RenderDescriptionType = {
  description: string;
  shopDetails?: IShop;
  productGroup: ProductGroup;
};

enum DescriptionTabs {
  DESCRIPTION = "DESCRIPTION",
  REVIEWS = "REVIEWS",
  ABOUT_SHOP = "ABOUT_SHOP",
  UPDATE_STOCK = "UPDATE_STOCK",
}

export const RenderDescription: React.FC<RenderDescriptionType> = ({
  description,
  shopDetails,
}): JSX.Element => {
  const [tab, setTab] = useState<DescriptionTabs>(DescriptionTabs.DESCRIPTION);

  const onClickTab = (selectedTab: DescriptionTabs): void =>
    setTab(selectedTab);

  const renderDescriptionSection = (): JSX.Element => (
    <div className="w-full p-4">{!!description && parse(description)}</div>
  );

  const shopCity: City = shopDetails?.address?.city! as City;
  const shopTown: Town = shopDetails?.address?.town! as Town;
  const shopCountry: Country = shopDetails?.address?.country as Country;
  const shopPhoneNumber: string = shopDetails?.address?.phoneNumber!;
  const renderShopDetails = (): JSX.Element => (
    <div className="w-full max-w-screen-xl flex flex-col p-4 md:p-8 h-80 md:flex-row">
      <div className="w-full md:w-2/5 flex pl-8 flex-col flex-1 items-start flex-wrap">
        <div className="flex w-full">
          <div className="flex flex-col justify-center items-center">
            <img src={shopDetails?.logo} className="w-20 h-20 rounded-full" />
            <p className="font-bold text-xl font-rounded text-gray-700 mb-0 mt-2">
              {shopDetails?.name}
            </p>
          </div>
          <div className="flex flex-col font-bold text-blue-grey-800 font-rounded mx-8 opacity-80 text-lg justify-between">
            <p className="mb-0">{shopDetails?.address.streetAddress}</p>
            <p className="mb-0">{shopTown?.name}</p>
            <p className="mb-0">{shopCity?.name}</p>
            <p className="mb-0">{shopCountry?.name}</p>
            <p className="mb-0">{`+${shopCountry?.dialCode}${shopPhoneNumber}`}</p>
          </div>
        </div>

        <div className="bg-gray-300 max-w-screen-sm w-full mt-8 p-2 rounded-lg flex justify-between">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <div>Registered on</div>
          <div>
            {new Date(shopDetails?.createdAt!).toLocaleDateString("en-GB")}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col w-full max-w-screen-xl rounded-b-lg overflow-hidden"
      style={{ minHeight: "40vh" }}
    >
      <div className="w-full flex bg-gray-300 font-rounded">
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.DESCRIPTION
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.DESCRIPTION)}
        >
          Description
        </button>
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.REVIEWS
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.REVIEWS)}
        >
          Reviews
        </button>
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.ABOUT_SHOP
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.ABOUT_SHOP)}
        >
          About Shop
        </button>
      </div>

      <div className="w-full flex flex-col relative">
        {tab === DescriptionTabs.DESCRIPTION && renderDescriptionSection()}
        {tab === DescriptionTabs.ABOUT_SHOP && renderShopDetails()}
      </div>
    </div>
  );
};
