import React, { ReactNode, useState } from "react";

type IZInput = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit?: React.FormEvent<HTMLFormElement>;
  value?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  ref?: any;
  type?: string;
  name?: string;
  autoComplete?: string;
  leftElement?: ReactNode;
  containerClassName?: string;
  label?: string;
};

export const ZInput = ({
  onChange,
  value,
  className,
  disabled,
  placeholder,
  ref,
  type,
  name,
  autoComplete,
  leftElement,
  containerClassName,
  label,
}: IZInput) => {
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <>
      {!!label && (
        <label
          className="text-left text-zimx-indigo-dark w-full font-bold"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div
        className={`${
          disabled ? "bg-gray-300" : "bg-zimx-indigo-light"
        }  flex  transition-all border-2 rounded-lg w-full ${focused &&
          "border-zimx-indigo-dark"} ${containerClassName}`}
      >
        {leftElement}
        <input
          placeholder={placeholder}
          className={`
        px-4
        focus:outline-none bg-transparent
         ease-in-out font-bold text-zimx-indigo-dark h-12 flex-1
        ${className}
        `}
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
          type={type}
          name={name}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};
