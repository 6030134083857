import React from "react";
import { useHistory } from "react-router-dom";
import { useShop } from "../shop/hooks/useShop";

export const Sidebar = () => {
  const { shop } = useShop();
  const history = useHistory();
  const onClickOrders = () => {
    history.push("/shop/products/orders");
  };

  const onClickProducts = () => {
    history.push("/shop/products");
  };

  // const onClickAccount = () => {
  //   history.push("/shop/account/connect");
  // };

  return (
    <div className="w-0 hidden md:flex flex-col md:w-1/5 shadow-2xl fixed left-0 top-0 bg-zimx-indigo-dark bg-opacity-10 h-full min-h-screen">
      <div className="w-full relative" style={{ aspectRatio: "1" }}>
        <img src={shop?.logo} className="w-full" style={{ aspectRatio: "1" }} />
        <div className=" h-full absolute bottom-0 left-0 w-full from-transparent to-sidebar bg-gradient-to-b" />
      </div>
      <div
        className="py-2 px-4 w-full transition-all duration-300 border-b border-zimx-indigo-dark border-opacity-10 text-gray-700 bg-zimx-indigo-dark bg-opacity-10 hover:bg-opacity-80 hover:text-white font-rounded font-bold cursor-pointer"
        onClick={onClickProducts}
      >
        Products
      </div>
      <div
        onClick={onClickOrders}
        className="py-2 px-4 w-full transition-all duration-300 border-b border-zimx-indigo-dark border-opacity-10 text-gray-700 bg-zimx-indigo-dark bg-opacity-10 hover:bg-opacity-80 hover:text-white font-rounded font-bold cursor-pointer"
      >
        Orders
      </div>
      {/* <div
        onClick={onClickAccount}
      >
        Account
      </div> */}

      <div className="flex flex-auto w-full" />
      <div className="py-2 px-4 self-end mb-16 text-gray-700 bg-red-900 bg-opacity-30 font-rounded font-bold fixed bottom-0 w-1/5 hover:bg-opacity-80 hover:text-white cursor-pointer">
        Log Out
      </div>
    </div>
  );
};
