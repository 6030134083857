import React, { useEffect, useMemo, useState } from "react";
import { Manager, Popper, Reference } from "react-popper";
import Select from "react-select";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { ReactSVG } from "react-svg";
import { findProduct } from "../../../redux/actions/search.actions";
import { ProductGroup } from "../../../redux/reducers/shop.reducer";
import searchIcon from "../../../assets/icons/search.svg";
import { ZInput } from "./ZInput";

type ISearchBox = {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
};

interface FormElements extends HTMLFormControlsCollection {
  search: HTMLInputElement;
}

interface SearchFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export const ZSearchInput = ({
  className,
  disabled,
  placeholder,
}: ISearchBox) => {
  const [value, setValue] = useState<string | null>();
  const params = useParams<{ text: string }>();
  const [searchResults, setSearchResults] = useState<ProductGroup[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const searchText = useMemo(() => {
    const searchParams = location.search;
    const urlParams = new URLSearchParams(searchParams);
    const s = urlParams.get("text");

    return s;
  }, [location.search]);

  useEffect(() => {
    if (searchText) {
      setValue(searchText);
    }
  }, [searchText]);

  const getQueryResults = async () => {
    if (value && searchText !== value) {
      const response = await dispatch(findProduct({ text: value }));

      if (response && response.length) {
        setSearchResults((response as unknown) as ProductGroup[]);
        setShowSuggestions(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent<SearchFormElement>) => {
    e.preventDefault();
    setShowSuggestions(false);
    const searchParams = new URLSearchParams({
      ...params,
      text: e.currentTarget.elements.search.value,
    });
    setValue("");
    history.push({ pathname: "/search", search: searchParams.toString() });
  };

  useEffect(() => {
    getQueryResults();
  }, [value, searchText]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value || "";
    setValue(searchTerm);
  };

  const onClick = (searchTerm: string) => {
    if (searchTerm) {
      setShowSuggestions(false);
      const searchParams = new URLSearchParams({ ...params, text: searchTerm });
      history.push({ pathname: "/search", search: searchParams.toString() });
    }
  };

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <form
            onSubmit={onSubmit}
            className="w-full md:w-1/2 flex px-2"
            ref={ref}
          >
            <ZInput
              leftElement={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <ReactSVG
                  src={searchIcon}
                  className="bg-transparent ml-4 h-full flex items-center justify-center text-gray-500"
                />
              }
              onChange={onChange}
              value={value || ""}
              className={`w-full mx-2 h-12 ${className}`}
              placeholder={placeholder}
              disabled={disabled}
              name="search"
            />
          </form>
        )}
      </Reference>
      {value && value.trim() !== "" && value !== searchText && showSuggestions && (
        <Popper placement="bottom-end">
          {({ ref, style }) => (
            <>
              <div
                ref={ref}
                style={{ ...style, backgroundColor: "#fff" }}
                className="shadow-md flex flex-col items-center border-gray-100 rounded-b-md border-t-4 md:w-1/2 mt-2 w-full z-10"
              >
                {searchResults.map((result) => (
                  <div
                    id={result._id}
                    className="w-full px-4 py-2 hover:bg-gray-200 font-bold transition-all duration-300"
                    onClick={() => onClick(result.name)}
                  >
                    {result.name}
                  </div>
                ))}
              </div>
            </>
          )}
        </Popper>
      )}

      <Modal
        className="w-full md:w-1/3 bg-gray-100 rounded-lg overflow-hidden focus:outline-none z-50"
        onRequestClose={() => toggleModal()}
        shouldCloseOnEsc
        overlayClassName="bg-black bg-opacity-20 w-screen h-screen absolute top-0 left-0 items-center justify-center flex overflow-hidden"
        isOpen={showModal}
        appElement={document?.querySelector("#root") as HTMLElement}
      >
        <div className="w-full p-8 bg-indigo-700 rounded-lg">
          <p className="font-bold capitalize text-2xl text-indigo-900 opacity-80">
            {" "}
            Search Filter
          </p>
          <div className="flex items-center justify-between px-2">
            <label className="text-gray-50 font-bold">Min Price</label>
            <ZInput
              onChange={() => null}
              value="Hello"
              placeholder="Enter min price"
              className="font-bold px-1 py-2 mr-1 w-1/4"
            />
            <label htmlFor="" className="text-gray-50 font-bold">
              Max Price
            </label>
            <ZInput
              onChange={() => null}
              value="Hello"
              placeholder="Enter Max price"
              className="font-bold py-2 px-1 ml-1 w-1/4"
            />
          </div>
          <Select
            options={[
              { label: "New", value: "new" },
              { label: "Used", value: "used" },
            ]}
            className="mt-4"
          />

          <Select
            options={[
              { label: "New", value: "new" },
              { label: "Used", value: "used" },
            ]}
            className="mt-4"
          />
        </div>
      </Modal>
    </Manager>
  );
};
