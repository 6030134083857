/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ScreenContainer } from "../../components/common/screenContainer";
import { IShop, ProductGroup } from "../../redux/reducers/shop.reducer";
import { ProductItem } from "../../components/common/ProductItem";
import { RequestMethods, fetchFactory } from "../../redux/actions/fetchFactory";
import { useProducts } from "../../components/shop/hooks/useProducts";
import "./CustomerVIewShopStyles.css";

const useShopData = (shopId: string) => {
  const { data, isLoading } = useQuery<IShop>(
    `shop customer ${shopId}`,
    async () =>
      fetchFactory({
        method: RequestMethods.GET,
        endpoint: `/api/shop/${shopId}`,
        errorMessage: "Failed to get shop products",
      }),
    { enabled: !!shopId },
  );

  return {
    shop: data,
    isLoading,
  };
};

export const CustomerViewShop: React.FC<{}> = () => {
  const { shopId } = useParams<{ shopId: string }>();
  const { products = [] } = useProducts(shopId);
  const { shop, isLoading } = useShopData(shopId);

  const history = useHistory();
  const onClickProduct = (product: ProductGroup) => {
    history.push(`/search/${product?._id}`);
  };

  const renderProducts = (): JSX.Element[] | null => {
    if (products) {
      return products?.map?.((product) => (
        <ProductItem
          product={product}
          key={product?._id}
          onClick={onClickProduct}
        />
      ));
    }

    return null;
  };

  const renderShopLogo = useCallback(() => {
    return (
      <div className=" flex row justify-center">
        <img className="w-8 h-8 mr-4 rounded-full" src={shop?.logo} />
        <text className="truncate">{shop?.name}</text>
        <div className="flex-1" />
        <a href="https://hello.world">
          <div className="bg-whatsapp flex justify-center align-middle h-8 w-8 rounded-md">
            <FontAwesomeIcon color="white" icon={faWhatsapp} />
          </div>
        </a>
      </div>
    );
  }, [shop?.id]);

  return (
    <ScreenContainer title={renderShopLogo()} loading={isLoading}>
      <div className="customer-shop-grid">{renderProducts()}</div>
    </ScreenContainer>
  );
};
