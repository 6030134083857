import { useMutation } from "react-query";
import { RequestMethods, fetchFactory } from "../../redux/actions/fetchFactory";

export const useStripeAccount = () => {
  const createAccount = useMutation(
    async () =>
      fetchFactory({
        endpoint: `/api/accounts`,
        method: RequestMethods.POST,
        errorMessage: "Failed to create account",
        successMessage: "Updated account successfully",
      }),
    {
      onSuccess: (response) => {
        window.open(response.url, "_self");
      },
    },
  );

  const onCreateAccount = () => {
    createAccount.mutate();
  };

  return { onCreateAccount };
};
