import React from "react";
import { OrderColumn } from "../../components/common/OrderColumn";
import { OrderItem } from "../../components/common/OrderItem";
import { OrderStatus } from "../../components/common/OrderItem/OrderItem.types";
import { ScreenContainer } from "../../components/common/screenContainer";
import { useProductOrders } from "./useOrders";

export const Orders = (): JSX.Element => {
  const { orders, loading, onUpdateOrder } = useProductOrders();
  console.log(orders);
  const renderNewOrders = () =>
    orders
      ?.filter((order) => order.orderStatus === OrderStatus.CREATED)
      ?.map((order) => <OrderItem item={order} onClick={onUpdateOrder} />);

  const renderShippedOrders = () =>
    orders
      ?.filter((order) => order.orderStatus === OrderStatus.SHIPPED)
      ?.map((order) => <OrderItem item={order} onClick={onUpdateOrder} />);

  const renderDeliveredOrders = () =>
    orders
      ?.filter((order) => order.orderStatus === OrderStatus.DELIVERED)
      ?.map((order) => <OrderItem item={order} onClick={onUpdateOrder} />);
  return (
    <ScreenContainer title="Orders" loading={loading}>
      <div className="w-full flex gap-4 mt-4">
        <OrderColumn title="Created">{renderNewOrders()}</OrderColumn>
        <OrderColumn title="Shipped">{renderShippedOrders()}</OrderColumn>
        <OrderColumn title="Delivered">{renderDeliveredOrders()}</OrderColumn>
      </div>
    </ScreenContainer>
  );
};
